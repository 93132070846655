import React from "react"

function ProductDetailContent({ content }: any) {
  return (
    <section className="relative py-16 md:py-24">
      <section className="container">
        <div className="grid grid-cols-1 lg:grid-cols-12" id="reserve-form">
          <div className="lg:col-span-10 lg:col-start-2">
            <p
              className="product-content mx-auto max-w-xl text-justify text-slate-500 dark:text-slate-300"
              dangerouslySetInnerHTML={{ __html: content }}
            ></p>
          </div>
        </div>
      </section>
    </section>
  )
}

export default ProductDetailContent
