import { graphql } from "gatsby"
import { features } from "process"
import React from "react"
import IndexFeatures from "../components/Index/IndexFeaturesContainer"
import Layout from "../components/Layout"
import ProductDetailContent from "../components/Products/ProductDetailContent"
import ProductDetailsHeader from "../components/Products/ProductDetailsHeader"
import Seo from "../components/Seo"

function ProductDetail({ data }: any) {
  const { name, description, lang, features, header_img } =
    data.static.frontmatter

  const content = data.static.html

  const seo = {
    metaTitle: name,
  }

  const header_img_data = data.staticImg.nodes.find((el: any) => {
    return header_img == el.name
  })?.childrenImageSharp[0]

  return (
    <Layout navLight={true}>
      <Seo seo={seo} />
      <ProductDetailsHeader
        title={name}
        subtitle={description}
        lang={lang}
        header_img={header_img_data}
      />
      <ProductDetailContent content={content} />
      <IndexFeatures features={features} />
    </Layout>
  )
}

export default ProductDetail

export const query = graphql`
  query ($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    static: markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { lang: { eq: $language } }
    ) {
      html
      frontmatter {
        name
        description
        lang
        header_img
        features {
          title
          text
          logo
        }
      }
    }
    staticImg: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativePath: { glob: "assets/products/*" }
      }
    ) {
      nodes {
        name
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
