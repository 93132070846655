import React from "react"
import { IFeature } from "../../libs/interfaces"

function IndexFeatureCard({ title, text, logo }: IFeature) {
  return (
    <div className="flex">
      <div className="flex h-[56px] min-w-[56px] items-center justify-center rounded-lg border-2 border-primary-600/20 bg-primary-600/5 align-middle text-xl text-primary-600 shadow-sm dark:shadow-gray-700">
        <i className={`mdi ${logo}`}></i>
      </div>

      <div className="content ml-6">
        <p className="text-lg font-medium">{title}</p>
        <p className="mt-3 text-slate-500 dark:text-slate-300">{text}</p>
      </div>
    </div>
  )
}

export default IndexFeatureCard
