import React from "react"
import { IFeature } from "../../libs/interfaces"
import IndexFeatureCard from "./IndexFeatureCard"

function IndexFeatures({ features }: { features: IFeature[] }) {
  return (
    <section className="relative bg-gray-50 py-16 dark:bg-slate-800">
      <div className="container">
        <div className="grid grid-cols-1 gap-[30px] md:grid-cols-2 lg:grid-cols-2">
          {features &&
            features.map((feature: any) => {
              return (
                <IndexFeatureCard
                  title={feature.title}
                  text={feature.text}
                  logo={feature.logo}
                  key={feature.title}
                />
              )
            })}
        </div>
      </div>
    </section>
  )
}

export default IndexFeatures
